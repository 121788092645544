import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc, onSnapshot, query, where } from 'firebase/firestore';
import { FiArrowLeft, FiEdit3, FiTrash, FiPlus, FiCheckCircle } from 'react-icons/fi';
import { motion } from 'framer-motion';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import { logout } from '../../firebase/auth';

function MenuManager() {
  const { eventId } = useParams();
  const [menuItems, setMenuItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newMenuItem, setNewMenuItem] = useState({
    name: '',
    category: 'Mains',
    quantity: 1,
    allergyInfo: '',
    claimedBy: [],
    addedBy: ''
  });
  const [editMenuItemId, setEditMenuItemId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [userMap, setUserMap] = useState({});
  const navigate = useNavigate();

  // Fetch menu items and user data for claims
  useEffect(() => {
    const fetchData = async () => {
      const storedUser = localStorage.getItem('user');
      const userEmail = storedUser ? JSON.parse(storedUser).email : null;

      if (!userEmail) {
        alert('User email is missing. Please log in again.');
        navigate('/login');
        return;
      }

      // Fetch menu items in real-time
      const unsubscribeMenuItems = onSnapshot(collection(db, 'events', eventId, 'menuItems'), snapshot => {
        const menuList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          claimedBy: Array.isArray(doc.data().claimedBy) ? doc.data().claimedBy : []
        }));
      
        // Sort menuList by remaining quantity in descending order
        const sortedMenuList = menuList.sort((a, b) => {
          const remainingA = a.quantity - a.claimedBy.length;
          const remainingB = b.quantity - b.claimedBy.length;
          return remainingB - remainingA;
        });
      
        setMenuItems(sortedMenuList);
      });
      

      // Fetch RSVPs for user names
      const rsvpsCollection = collection(db, 'events', eventId, 'rsvps');
      const rsvpsSnapshot = await getDocs(rsvpsCollection);
      const rsvpsMap = {};
      rsvpsSnapshot.forEach(doc => {
        const { email, name } = doc.data();
        rsvpsMap[email] = name;
      });
      setUserMap(rsvpsMap);

      return () => unsubscribeMenuItems();
    };

    fetchData();
  }, [eventId, navigate]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setEditMenuItemId(null);
    setNewMenuItem({ name: '', category: 'Mains', quantity: 1, allergyInfo: '', claimedBy: [], addedBy: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMenuItem(prevItem => ({
      ...prevItem,
      [name]: name === 'quantity' ? Math.max(1, parseInt(value) || 1) : value
    }));
  };

  const handleCreateOrUpdateMenuItem = async (e) => {
    e.preventDefault();
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    if (!userEmail) {
      alert('User email is missing. Please log in again.');
      navigate('/login');
      return;
    }

    try {
      const menuCollection = collection(db, 'events', eventId, 'menuItems');

      const updatedMenuItem = {
        ...newMenuItem,
        addedBy: userEmail,
        claimedBy: newMenuItem.claimedBy || []
      };

      if (editMenuItemId) {
        const menuDocRef = doc(db, 'events', eventId, 'menuItems', editMenuItemId);
        await updateDoc(menuDocRef, updatedMenuItem);
      } else {
        await addDoc(menuCollection, updatedMenuItem);
      }
      closeModal();
    } catch (error) {
      console.error('Error adding/updating menu item: ', error);
    }
  };

  const handleEditMenuItem = (menuItem) => {
    setEditMenuItemId(menuItem.id);
    setNewMenuItem({
      name: menuItem.name,
      category: menuItem.category,
      quantity: menuItem.quantity,
      allergyInfo: menuItem.allergyInfo,
      claimedBy: Array.isArray(menuItem.claimedBy) ? menuItem.claimedBy : [],
      addedBy: menuItem.addedBy || ''
    });
    openModal();
  };

  const handleDeleteMenuItem = async (menuItemId) => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    const menuItem = menuItems.find(item => item.id === menuItemId);

    if (menuItem && menuItem.addedBy === userEmail) {
      try {
        const menuDocRef = doc(db, 'events', eventId, 'menuItems', menuItemId);
        await deleteDoc(menuDocRef);
      } catch (error) {
        console.error('Error deleting menu item: ', error);
      }
    } else {
      alert("You can only delete menu items that you have created.");
    }
  };

//testing multi claim 
const [claimQuantity, setClaimQuantity] = useState(1);
const [unclaimQuantity, setUnclaimQuantity] = useState(1);

const handleClaimMenuItem = async (menuItemId, claimerEmail, claimQuantity, isUnclaim = false) => {
  try {
    const menuDocRef = doc(db, 'events', eventId, 'menuItems', menuItemId);
    const menuItem = menuItems.find(item => item.id === menuItemId);

    if (!menuItem) {
      console.error('Menu item not found');
      return;
    }

    const userClaims = menuItem.claimedBy.filter(email => email === claimerEmail).length;

    if (isUnclaim) {
      // Validate unclaim quantity
      if (claimQuantity > userClaims) {
        alert(`You can only unclaim up to ${userClaims} items.`);
        return;
      }

      // Remove the specified number of claims for the user
      let remainingClaims = claimQuantity;
      const updatedClaims = menuItem.claimedBy.filter(email => {
        if (email === claimerEmail && remainingClaims > 0) {
          remainingClaims -= 1;
          return false; // Remove this claim
        }
        return true; // Keep this claim
      });

      await updateDoc(menuDocRef, { claimedBy: updatedClaims });
    } else {
      // Claim logic
      const remaining = menuItem.quantity - menuItem.claimedBy.length;
      if (claimQuantity > remaining) {
        alert(`Only ${remaining} items remaining to claim.`);
        return;
      }

      const newClaims = Array(claimQuantity).fill(claimerEmail);
      const updatedClaims = [...menuItem.claimedBy, ...newClaims];

      await updateDoc(menuDocRef, { claimedBy: updatedClaims });
    }
  } catch (error) {
    console.error('Error updating claim status:', error);
  }
};

  

  const handleFilterChange = (category) => setSelectedCategory(category);

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Standardized Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logo}
          alt="PartySync Logo"
          className="h-16 cursor-pointer"
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={handleLogout}
        >
          LOG OUT
        </button>
      </header>
  
      <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
        {/* Back Button Below Header */}
        <div className="flex justify-start items-center mb-4">
          <button
            className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
            onClick={() => navigate(-1)}
          >
            <FiArrowLeft size={32} />
          </button>
        </div>
  
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-fontGreen mb-6">BUILD YOUR MENU</h2>
  
          <div className="flex gap-3 mb-8">
            {['All', 'Appetizers', 'Mains', 'Sides', 'Desserts', 'Drinks', 'Others'].map(category => (
              <button
                key={category}
                className={`px-5 py-2 rounded-full font-semibold transition-all ${selectedCategory === category ? 'bg-canvared text-white' : 'bg-pastelcream text-fontGreen'}`}
                onClick={() => handleFilterChange(category)}
              >
                {category}
              </button>
            ))}
          </div>
  
          <div onClick={openModal} className="border-2 border-yellow p-6 rounded-2xl bg-yellow flex items-center justify-center cursor-pointer hover:scale-105 mb-6">
            <FiPlus size={48} className="text-white font-bold mb-2" />
            <p className="text-xl font-bold text-white">New Menu Item</p>
          </div>
  
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {menuItems
              .filter(item => selectedCategory === 'All' || item.category === selectedCategory)
              .map(item => (
                <motion.div
                  key={item.id}
                  className="border-4 border-fontGreen p-6 rounded-2xl bg-gradient-to-br from-white to-white text-fontGreen cursor-pointer hover:scale-105 relative"
                  initial={{ scale: 0.95 }}
                  animate={{ scale: 1 }}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="text-md font-bold italic bg-white text-fontGreen px-2 py-1 rounded-full absolute bottom-4 left-4">
                    {item.category}
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold uppercase text-fontGreen">{item.name}</h3>
  
                    {item.claimedBy.includes(JSON.parse(localStorage.getItem('user')).email) ? (
                      <div className="flex gap-2 items-center">
                        <input
                          type="number"
                          min="1"
                          max={item.claimedBy.filter(email => email === JSON.parse(localStorage.getItem('user')).email).length}
                          defaultValue={1}
                          onChange={(e) => setUnclaimQuantity(Number(e.target.value))}
                          className="w-14 h-8 p-2 border-2 border-fontGreen rounded-2xl"
                        />
                        <button
                          className="text-md font-bold uppercase text-white px-4 py-1 bg-darkgrey hover:scale-125 rounded-full transition"
                          onClick={() => handleClaimMenuItem(item.id, JSON.parse(localStorage.getItem('user')).email, unclaimQuantity, true)}
                        >
                          Drop
                        </button>
                      </div>
                    ) : item.claimedBy.length < item.quantity ? (
                      <div className="flex gap-2 items-center">
                        <input
                          type="number"
                          min="1"
                          max={item.quantity - item.claimedBy.length}
                          defaultValue={1}
                          onChange={(e) => setClaimQuantity(Number(e.target.value))}
                          className="w-14 h-8 p-2 border-2 border-fontGreen rounded-2xl"
                        />
                        <button
                          className="text-md font-bold text-white uppercase px-4 py-1 bg-canvared hover:scale-125 rounded-full transition"
                          onClick={() => handleClaimMenuItem(item.id, JSON.parse(localStorage.getItem('user')).email, claimQuantity)}
                        >
                          Claim
                        </button>
                      </div>
                    ) : (
                      <span className="text-md font-bold bg-darkgrey uppercase text-white px-2 py-1 rounded-full">
                        Taken
                      </span>
                    )}
                  </div>
                  <p className="text-lg text-fontGreen">Quantity: {item.claimedBy.length} of {item.quantity} claimed</p>

                  <p className="text-lg text-fontGreen">
                    Claimed By: {
                      Object.entries(
                        item.claimedBy.reduce((acc, email) => {
                          acc[email] = (acc[email] || 0) + 1; // Count claims per user
                          return acc;
                        }, {})
                      )
                      .map(([email, count]) => {
                        const displayName = userMap[email] || email;
                        return count > 1 ? `${displayName} (${count}x)` : displayName;
                      })
                      .join(', ') || 'None'
                    }
                  </p>
                  <p className="text-lg text-fontGreen">Allergy Info: {item.allergyInfo || 'None'}</p>
                  <div className="flex justify-end gap-2 mt-4">
                    <button className="text-fontGreen hover:scale-125 text-fontGreen transition" onClick={() => handleEditMenuItem(item)}>
                      <FiEdit3 size={24} />
                    </button>
                    <button className="text-fontGreen hover:scale-125 text-fontGreen transition" onClick={() => handleDeleteMenuItem(item.id)}>
                      <FiTrash size={24} />
                    </button>
                  </div>
                </motion.div>
              ))}
          </div>
          {isModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
      <h3 className="text-xl font-semibold text-darkgrey mb-4">{editMenuItemId ? 'Edit Menu Item' : 'Add New Menu Item'}</h3>
      <form onSubmit={handleCreateOrUpdateMenuItem} className="flex flex-col gap-4">
        <input
          type="text"
          name="name"
          placeholder="Item Name"
          value={newMenuItem.name}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
          required
        />
        <select
          name="category"
          value={newMenuItem.category}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
        >
          <option value="Mains">Mains</option>
          <option value="Appetizers">Appetizers</option>
          <option value="Desserts">Desserts</option>
          <option value="Drinks">Drinks</option>
          <option value="Sides">Sides</option>
          <option value="Others">Others</option>
        </select>
        <input
          type="number"
          name="quantity"
          placeholder="Quantity"
          value={newMenuItem.quantity}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
          min="1"
        />
        <input
          type="text"
          name="allergyInfo"
          placeholder="Allergy Info (Optional)"
          value={newMenuItem.allergyInfo}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <div className="flex justify-between mt-6">
          <button
            type="button"
            className="bg-gray-300 text-dark py-2 px-5 rounded-lg hover:bg-gray-400 transition"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 bg-primary text-white py-2 px-5 rounded-lg hover:bg-purple-600 transition"
          >
            {editMenuItemId ? 'Update Item' : 'Add Item'}
          </button>
        </div>
      </form>
    </div>
  </div>
)}





        </div>
      </main>
    </div>
  );
  
}

export default MenuManager;
