import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, onSnapshot, addDoc, getDocs, query, where, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { FiArrowLeft, FiCheckCircle, FiXCircle, FiPlus, FiMail, FiTrash, FiEdit, FiChevronUp, FiChevronDown, FiLogOut } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import logo from '../../assets/images/logos/logo-partysync-official.png';

// Event banner imports
import birthdayBanner from '../../assets/images/eventBanners/birthday-banner.png';
import graduationBanner from '../../assets/images/eventBanners/graduation-banner.png';
import bacheloretteBanner from '../../assets/images/eventBanners/bachelorette-banner.png';
import thanksgivingBanner from '../../assets/images/eventBanners/thanksgiving-banner.png';
import defaultBanner from '../../assets/images/eventBanners/thanksgiving-banner.png';

function GuestEventDetails({ previewMode = false }) {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [rsvps, setRsvps] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [response, setResponse] = useState('Pending');
  const [newMenuItem, setNewMenuItem] = useState({
    name: '',
    category: 'Mains',
    quantity: 1,
    allergyInfo: '',
    claimedBy: [],
    addedBy: ''
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentMenuItemId, setCurrentMenuItemId] = useState(null);
  const [userAdded, setUserAdded] = useState(false);
  const [guestName, setGuestName] = useState('');
  const [allergyInfo, setAllergyInfo] = useState('');
  const [additionalGuests, setAdditionalGuests] = useState(0);
  const [additionalDogs, setAdditionalDogs] = useState(0);
  const [userMap, setUserMap] = useState({});
  const [isEnvelopeVisible, setIsEnvelopeVisible] = useState(false); // Initialize isEnvelopeVisible here
  const navigate = useNavigate();
  const rsvpCreatedRef = useRef(false); // To prevent duplicate RSVP creation


  // Multi claim and unclaim 
  const [claimQuantity, setClaimQuantity] = useState(1); // For tracking claim quantity
const [unclaimQuantity, setUnclaimQuantity] = useState(1); // For tracking unclaim quantity

  const eventBackgroundBanners = {
    Birthday: birthdayBanner,
    Graduation: graduationBanner,
    Bachelorette: bacheloretteBanner,
    Thanksgiving: thanksgivingBanner,
  };
  const backgroundImage = eventBackgroundBanners[event?.type] || defaultBanner;

  useEffect(() => {
    const checkAndCreateRSVP = async () => {
      const storedUser = localStorage.getItem('user');
      const user = storedUser ? JSON.parse(storedUser) : null;
      const userEmail = user ? user.email : null;

      if (!userEmail) {
        alert('User email is missing. Please log in again.');
        if (!previewMode) navigate('/login');
        return;
      }

      // Prevent multiple calls from creating duplicates
      if (rsvpCreatedRef.current) {
        console.log("RSVP creation already initiated, skipping...");
        return;
      }
      rsvpCreatedRef.current = true;

      try {
        const rsvpsCollection = collection(db, 'events', eventId, 'rsvps');
        const rsvpQuery = query(rsvpsCollection, where('email', '==', userEmail));
        const rsvpSnapshot = await getDocs(rsvpQuery);

        if (!rsvpSnapshot.empty) {
          console.log("RSVP already exists for this email:", userEmail);

          const existingRSVP = rsvpSnapshot.docs[0];
          setRsvps([{ id: existingRSVP.id, ...existingRSVP.data() }]);
          setGuestName(existingRSVP.data().name || user.displayName || '');
          setAllergyInfo(existingRSVP.data().allergyInfo || '');
          setResponse(existingRSVP.data().response || 'Pending');
          setAdditionalGuests(existingRSVP.data().additionalGuests || 0);
          setAdditionalDogs(existingRSVP.data().additionalDogs || 0);
          setUserAdded(true);
        } else if (!userAdded) {
          console.log("No RSVP found for this email. Creating a new RSVP document...");

          // Create a new RSVP entry if one does not exist
          await addDoc(rsvpsCollection, {
            email: userEmail,
            name: user.displayName || 'Guest',
            response: 'Pending',
            allergyInfo: 'None',
            additionalGuests: 0,
            additionalDogs: 0,
            isCoHost: false
          });

          setUserAdded(true);
          console.log("New RSVP document created for:", userEmail);
        }
      } catch (error) {
        console.error("Error checking or creating RSVP:", error);
      }

      // Set up a single real-time listener for RSVPs
      const unsubscribeRSVP = onSnapshot(
        collection(db, 'events', eventId, 'rsvps'),
        (snapshot) => {
          const rsvpList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setRsvps(rsvpList);

          // Refresh userMap for each RSVP
          const userMapObj = {};
          rsvpList.forEach(({ email, name }) => {
            userMapObj[email] = name || email;
          });
          setUserMap(userMapObj);

           // Calculate counts right after setting RSVPs
        const yesCount = getTotalYesCount();
        console.log("Initial confirmed guest count (including additional guests):", yesCount);
      

          // Update current user's RSVP information if it exists
          const currentUserRsvp = rsvpList.find((rsvp) => rsvp.email === userEmail);
          if (currentUserRsvp) {
            setGuestName(currentUserRsvp.name || '');
            setAllergyInfo(currentUserRsvp.allergyInfo || '');
            setResponse(currentUserRsvp.response || 'Pending');
            setAdditionalGuests(currentUserRsvp.additionalGuests || 0);
            setAdditionalDogs(currentUserRsvp.additionalDogs || 0);
          }
        }
      );

      // Real-time listener for event details
      const unsubscribeEvent = onSnapshot(doc(db, 'events', eventId), (doc) => {
        if (doc.exists()) setEvent(doc.data());
      });

      return () => {
        unsubscribeRSVP();
        unsubscribeEvent();
      };
    };

    checkAndCreateRSVP();
  }, [eventId, navigate, previewMode, userAdded]);

  useEffect(() => {
    const rsvpsCollection = collection(db, 'events', eventId, 'rsvps');
    const unsubscribeRSVP = onSnapshot(rsvpsCollection, (snapshot) => {
      const rsvpList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRsvps(rsvpList);

      const userMapObj = {};
      rsvpList.forEach(({ email, name }) => {
        userMapObj[email] = name || email;
      });
      setUserMap(userMapObj);

      const storedUser = localStorage.getItem('user');
      const userEmail = storedUser ? JSON.parse(storedUser).email : null;
      const currentUserRsvp = rsvpList.find((rsvp) => rsvp.email === userEmail);
      if (currentUserRsvp) {
        setGuestName(currentUserRsvp.name || '');
        setAllergyInfo(currentUserRsvp.allergyInfo || '');
        setResponse(currentUserRsvp.response || 'Pending');
        setAdditionalGuests(currentUserRsvp.additionalGuests || 0);
        setAdditionalDogs(currentUserRsvp.additionalDogs || 0);
      }
    });

    const unsubscribeEvent = onSnapshot(doc(db, 'events', eventId), (doc) => {
      if (doc.exists()) setEvent(doc.data());
    });

    const unsubscribeMenuItems = onSnapshot(
      collection(db, 'events', eventId, 'menuItems'),
      (snapshot) => {
        const items = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          claimedBy: Array.isArray(doc.data().claimedBy) ? doc.data().claimedBy : [],
          addedBy: doc.data().addedBy || '',
        }));

        const sortedItems = items.sort((a, b) => {
          const remainingA = a.quantity - a.claimedBy.length;
          const remainingB = b.quantity - b.claimedBy.length;
          return remainingB - remainingA;
        });

        setMenuItems(sortedItems);
      }
    );

    return () => {
      unsubscribeRSVP();
      unsubscribeEvent();
      unsubscribeMenuItems();
    };
  }, [eventId]);

  const formatTime = (time) => {
    if (!time) return '';
    const [hour, minute] = time.split(':');
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    const formattedHour = hourInt % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const getTotalYesCount = () => {
    return rsvps.reduce((total, rsvp) => {
      if (rsvp.response === 'Yes') {
        return total + 1 + (rsvp.additionalGuests || 0); // 1 for the guest + additional guests
      }
      return total;
    }, 0);
  };
  

  const [isGuestListOpen, setIsGuestListOpen] = useState({
    Yes: false,
    No: false,
    Maybe: false,
    Pending: false,
  });

  const toggleGuestList = (status) => {
    setIsGuestListOpen((prevState) => ({
      ...prevState,
      [status]: !prevState[status],
    }));
  };

  

  const getGuestsByStatus = (status) => {
    const filteredGuests = rsvps.filter((rsvp) => rsvp.response === status);
    console.log(`Guests with ${status} status:`, filteredGuests); // Debugging log
    return filteredGuests;
  };

  const updateRsvpInfo = async (updatedFields) => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const rsvpDoc = rsvps.find((rsvp) => rsvp.email === userEmail);

    if (rsvpDoc) {
      try {
        const rsvpRef = doc(db, 'events', eventId, 'rsvps', rsvpDoc.id);
        await updateDoc(rsvpRef, updatedFields);
      } catch (error) {
        console.error('Error updating RSVP info:', error);
      }
    }
  };

  const updateAdditionalInfo = async () => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const rsvpDoc = rsvps.find((rsvp) => rsvp.email === userEmail);
  
    if (rsvpDoc) {
      try {
        const rsvpRef = doc(db, 'events', eventId, 'rsvps', rsvpDoc.id);
        await updateDoc(rsvpRef, {
          additionalGuests,
          additionalDogs,
        });
      } catch (error) {
        console.error('Error updating additional guests/dogs:', error);
      }
    }
  };
  
  useEffect(() => {
    if (response === 'Yes') {
      updateAdditionalInfo();
    }
  }, [additionalGuests, additionalDogs, response]);
  

  const handleRsvpChange = async (responseStatus) => {
    setResponse(responseStatus);

    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const rsvpDoc = rsvps.find((rsvp) => rsvp.email === userEmail);

    if (rsvpDoc) {
      try {
        const rsvpRef = doc(db, 'events', eventId, 'rsvps', rsvpDoc.id);
        await updateDoc(rsvpRef, {
          response: responseStatus,
          additionalGuests: responseStatus === 'Yes' ? additionalGuests : 0,
          additionalDogs: responseStatus === 'Yes' ? additionalDogs : 0,
        });
      } catch (error) {
        console.error('Error updating RSVP:', error);
      }
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setNewMenuItem({ name: '', category: 'Mains', quantity: 1, allergyInfo: '', claimedBy: [], addedBy: '' });
  };

  const handleAddMenuItem = async (e) => {
    e.preventDefault();
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    if (!userEmail) {
      alert('User email is missing. Please log in again.');
      return;
    }

    try {
      const menuCollection = collection(db, 'events', eventId, 'menuItems');
      const updatedMenuItem = {
        ...newMenuItem,
        addedBy: userEmail,
        claimedBy: newMenuItem.claimedBy || []
      };

      if (isEditMode && currentMenuItemId) {
        const menuDocRef = doc(db, 'events', eventId, 'menuItems', currentMenuItemId);
        await updateDoc(menuDocRef, updatedMenuItem);
      } else {
        await addDoc(menuCollection, updatedMenuItem);
      }
      closeModal();
    } catch (error) {
      console.error('Error adding/updating menu item: ', error);
    }
  };

  const handleEditMenuItem = (menuItem) => {
    setIsEditMode(true);
    setCurrentMenuItemId(menuItem.id);
    setNewMenuItem({
      name: menuItem.name,
      category: menuItem.category,
      quantity: menuItem.quantity,
      allergyInfo: menuItem.allergyInfo,
      claimedBy: Array.isArray(menuItem.claimedBy) ? menuItem.claimedBy : [],
      addedBy: menuItem.addedBy
    });
    setIsModalOpen(true);
  };

  const handleClaimMenuItem = async (menuItemId, claimQuantity) => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const menuItem = menuItems.find((item) => item.id === menuItemId);
  
    if (menuItem && userEmail) {
      const remaining = menuItem.quantity - menuItem.claimedBy.length;
  
      if (claimQuantity > remaining) {
        alert(`Only ${remaining} items remaining to claim.`);
        return;
      }
  
      const newClaims = Array(claimQuantity).fill(userEmail);
      const updatedClaims = [...menuItem.claimedBy, ...newClaims];
  
      try {
        await updateDoc(doc(db, 'events', eventId, 'menuItems', menuItemId), { claimedBy: updatedClaims });
      } catch (error) {
        console.error('Error claiming menu items:', error);
      }
    }
  };
  
  const handleUnclaimMenuItem = async (menuItemId, unclaimQuantity) => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const menuItem = menuItems.find((item) => item.id === menuItemId);
  
    if (menuItem && userEmail) {
      const userClaims = menuItem.claimedBy.filter((email) => email === userEmail).length;
  
      if (unclaimQuantity > userClaims) {
        alert(`You can only unclaim up to ${userClaims} items.`);
        return;
      }
  
      let remainingToRemove = unclaimQuantity;
      const updatedClaims = menuItem.claimedBy.filter((email) => {
        if (email === userEmail && remainingToRemove > 0) {
          remainingToRemove -= 1;
          return false;
        }
        return true;
      });
  
      try {
        await updateDoc(doc(db, 'events', eventId, 'menuItems', menuItemId), { claimedBy: updatedClaims });
      } catch (error) {
        console.error('Error unclaiming menu items:', error);
      }
    }
  };
  

  const handleDeleteMenuItem = async (menuItemId) => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const menuItem = menuItems.find((item) => item.id === menuItemId);
    if (menuItem && menuItem.addedBy === userEmail) {
      try {
        await deleteDoc(doc(db, 'events', eventId, 'menuItems', menuItemId));
      } catch (error) {
        console.error('Error deleting menu item: ', error);
      }
    } else {
      alert("You can only delete menu items that you have created.");
    }
  };

  if (!event) {
    return <div className="text-center p-6">Loading Event Details...</div>;
  }

  const { guestViewSettings } = event;
  const showGuestList = guestViewSettings?.showGuestList ?? true;
  const showMenuList = guestViewSettings?.showMenuList ?? true;
  const showPartyChat = guestViewSettings?.showPartyChat ?? true; // <-- New toggle for party chat


  
  return (
<div className="flex flex-col min-h-screen">
      {/* Conditionally render the header if not in preview mode */}
      {!previewMode && (
        <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
          <img
            src={logo}
            alt="PartySync Logo"
            className="h-16 cursor-pointer"
       //     onClick={() => navigate('/')}
          />
          <button
            className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
            onClick={() => navigate('/')}
          >
            LOG OUT
          </button>
        </header>
      )}
  
      <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
        {/* Wrapping the content in a max-w-7xl container for consistent width */}
          <div className="flex justify-start items-center mb-6">
            <button className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg" onClick={() => navigate('/guest-dashboard')}>
              <FiArrowLeft size={32} />
            </button>
          </div>
          <div className="max-w-7xl mx-auto">

               {/* Event Details Banner */}
      <div
        className="flex flex-col md:flex-row justify-between items-start md:items-center bg-white p-4 md:p-6 rounded-lg shadow-md mb-6"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '350px',
          minHeight: '350px',  // Ensures a minimum height on small screens
        }}
      >
        <div className="flex flex-col bg-offwhite bg-opacity-90 p-4 md:p-8 rounded-2xl mb-4 md:mb-0">
          <span className="text-xl md:text-2xl font-bold text-fontGreen">{event.name}</span>
          <span className="text-lg md:text-xl text-fontGreen">
            Date: <span className="font-medium">{event.date}</span>
          </span>
          <span className="text-lg md:text-xl text-fontGreen">
            Time: <span className="font-medium">{formatTime(event.time)}</span>
          </span>
          <span className="text-lg md:text-xl text-fontGreen">
            Location: <span className="font-medium">{event.location}</span>
          </span>
        </div>
      </div>
  
  
      <div className="flex flex-col md:flex-row gap-4 mb-4">
  {/* RSVP Section */}
  <div className="flex-1 border-4 border-fontGreen bg-gradient-to-r from-white to-white text-white p-4 rounded-lg mb-6">
    <h3 className="text-2xl font-semibold text-fontGreen mb-3">RSVP</h3>
    <div className="flex gap-2 mb-4">
      <button
        className={`w-20 md:w-24 py-1 px-3 rounded-full ${
          response === 'Yes'
            ? 'text-lg font-bold bg-gradient-to-r from-green-600 to-green-600 text-white'
            : 'bg-gray-200 text-lg text-gray-600'
        }`}
        onClick={() => handleRsvpChange('Yes')}
      >
        Yes
      </button>
      <button
        className={`w-20 md:w-24 py-1 px-3 rounded-full ${
          response === 'No'
            ? 'text-lg font-bold bg-gradient-to-r from-red-600 to-red-600 text-white'
            : 'text-lg bg-gray-200 text-gray-600'
        }`}
        onClick={() => handleRsvpChange('No')}
      >
        No
      </button>
      <button
        className={`w-20 md:w-24 py-1 px-3 rounded-full ${
          response === 'Maybe'
            ? 'text-lg font-bold bg-gradient-to-r from-darkOrange to-darkOrange text-white'
            : 'text-lg bg-gray-200 text-gray-600'
        }`}
        onClick={() => handleRsvpChange('Maybe')}
      >
        Maybe
      </button>
    </div>

    {/* Additional Guests and Dogs fields */}
    {response === 'Yes' && (
      <div className="space-y-2">
        <div className="flex items-center gap-1">
          <label className="text-sm font-semibold text-fontGreen uppercase w-28">Extra Guests:</label>
          <input
            type="number"
            min="0"
            value={additionalGuests}
            onChange={(e) => setAdditionalGuests(parseInt(e.target.value) || 0)}
            className="p-1 border border-fontGreen rounded-lg w-12 text-fontGreen font-bold text-center shadow-sm"
          />
        </div>
        <div className="flex items-center gap-1">
          <label className="text-sm font-semibold uppercase text-fontGreen w-28">Dogs:</label>
          <input
            type="number"
            min="0"
            value={additionalDogs}
            onChange={(e) => setAdditionalDogs(parseInt(e.target.value) || 0)}
            className="p-1 border border-fontGreen rounded-lg w-12 text-fontGreen font-bold text-center shadow-sm"
          />
        </div>
      </div>
    )}
  </div>

  {/* About You Section */}
  <div className="flex-1 border-4 border-fontGreen bg-gradient-to-r from-white to-white text-white p-4 rounded-lg mb-6">
    <h3 className="text-2xl font-semibold uppercase text-fontGreen mb-3">About You</h3>
    
    {/* Name Input */}
    <div className="mt-2">
      <label className="text-sm uppercase font-bold text-fontGreen">Name:</label>
      <input
        type="text"
        value={guestName}
        onChange={(e) => setGuestName(e.target.value)}
        onBlur={() => updateRsvpInfo({ name: guestName })}
        className="p-2 border border-fontGreen rounded-lg w-full text-fontGreen font-medium mt-1 shadow-sm"
        placeholder="Enter your name"
      />
    </div>

    {/* Allergy Info Input */}
    <div className="mt-2">
      <label className="text-sm uppercase font-bold text-fontGreen">Allergy Info:</label>
      <input
        type="text"
        value={allergyInfo}
        onChange={(e) => setAllergyInfo(e.target.value)}
        onBlur={() => updateRsvpInfo({ allergyInfo })}
        className="p-2 border border-fontGreen rounded-lg w-full text-fontGreen font-medium mt-1 shadow-sm"
        placeholder="Enter allergy information"
      />
    </div>
  </div>
</div>



{isEnvelopeVisible && (
  <motion.div
    initial={{ opacity: 0, y: 50, scale: 0.5 }}
    animate={{ opacity: 1, y: -30, scale: 1.2 }}
    exit={{ opacity: 0, y: -50, scale: 0.8 }}
    transition={{ duration: 2.5, type: 'spring', stiffness: 400 }}
    style={{
      position: 'fixed',
      top: '40%',
      left: '40%',
      transform: 'translate(-40%, -40%)', // Center horizontally and vertically,
      zIndex: 100,
    }}
  >
    <FiMail size={84} className="text-fontGreen font-bold" />
  </motion.div>
)}

{/*  Party Chat Section */}
{showPartyChat && (
  <div
    className="p-4 mb-6 rounded-2xl bg-canvared flex flex-col items-center justify-center cursor-pointer hover:scale-105 transition animate-bounce"
    onClick={() => navigate(`/party-chat/${eventId}`)}
  >
    <p className="text-2xl uppercase font-bold text-white">🎉 Join Party Chat 🎉</p>
  </div>
)}


{/* Guest List Section */}
{showGuestList && (
  <div className="border-4 border-fontGreen bg-gradient-to-r from-white to-white p-6 rounded-lg mb-6">
    <h3 className="text-3xl font-bold uppercase text-fontGreen mb-4">Guest List</h3>
    {['Yes', 'No', 'Maybe', 'Pending'].map((status) => (
      <div key={status} className="mb-4">
        <div
          className="flex justify-between items-center cursor-pointer p-2 bg-fontGreen rounded-md"
          onClick={() => toggleGuestList(status)}
        >
          <h4 className="text-lg font-semibold text-white">
            {status} ({status === 'Yes' ? getTotalYesCount() : getGuestsByStatus(status).length})
          </h4>
          {isGuestListOpen[status] ? <FiChevronUp size={32} color="white" /> : <FiChevronDown size={32} color="white" />}
        </div>
        {isGuestListOpen[status] && (
          <ul className="border-2 border-fontGreen bg-offwhite p-4 rounded-md mt-2">
            {getGuestsByStatus(status).length > 0 ? (
              getGuestsByStatus(status).map((guest) => {
                const additionalInfo = [
                  guest.additionalGuests > 0 ? `+${guest.additionalGuests} Guest${guest.additionalGuests > 1 ? 's' : ''}` : '',
                  guest.additionalDogs > 0 ? `+${guest.additionalDogs} 🐶${guest.additionalDogs > 1 ? 's' : ''}` : ''
                ].filter(Boolean).join(' ');

                return (
                  <li key={guest.id} className="mb-2 font-bold text-fontGreen">
                    {guest.name} {additionalInfo && <span className="text-gray-500">({additionalInfo})</span>}
                  </li>
                );
              })
            ) : (
              <li className="text-fontGreen font-semibold">No guests in this category.</li>
            )}
          </ul>
        )}
      </div>
    ))}
  </div>
)}

  {/* Menu Section */}
  {showMenuList && (
  <div className="border-4 border-fontGreen bg-gradient-to-r from-white to-white p-6 rounded-lg mb-6">
    <h3 className="text-3xl font-bold text-fontGreen uppercase mb-4">Menu</h3>
    <button
      className="mt-4 flex items-center justify-center gap-2 bg-gradient-to-r from-canvared to-canvared font-bold text-white text-2xl py-2 px-4 rounded-2xl hover:scale-105 transition mb-4"
      onClick={openModal}
    >
      <FiPlus /> Add New Item
    </button>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {menuItems.map((item) => (
        <motion.div
          key={item.id}
          className="relative bg-fontGreen border-4 border-fontGreen p-4 rounded-lg hover: transition bg-fontGreen cursor-pointer"
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          whileHover={{ scale: 1.02 }}
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg text-white uppercase font-bold truncate max-w-[70%] relative group">
              {item.name}
              <span className="absolute left-0 w-max bg-black text-white text-sm rounded-md p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                {item.name}
              </span>
            </h3>
            <div className="absolute top-4 right-4 flex flex-col gap-2">
              {item.claimedBy.includes(JSON.parse(localStorage.getItem('user')).email) ? (
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    min="1"
                    max={item.claimedBy.filter(email => email === JSON.parse(localStorage.getItem('user')).email).length}
                    defaultValue={1}
                    onChange={(e) => setUnclaimQuantity(Number(e.target.value))}
                    className="w-14 h-8 p-2 bg-yellow border-2 border-fontGreen text-fontGreen bg-offwhite font-bold rounded-2xl"
                  />
                  <button
                    className="text-md font-bold text-white px-4 py-1 bg-medgrey hover:scale-125 rounded-full transition"
                    onClick={() => handleUnclaimMenuItem(item.id, unclaimQuantity)}
                  >
                    DROP
                  </button>
                </div>
              ) : (
                item.claimedBy.length < item.quantity && (
                  <div className="flex items-center gap-2">
                    <input
                      type="number"
                      min="1"
                      max={item.quantity - item.claimedBy.length}
                      defaultValue={1}
                      onChange={(e) => setClaimQuantity(Number(e.target.value))}
                      className="w-14 h-8 p-2 bg-yellow border-2 border-fontGreen text-fontGreen font-bold rounded-2xl appearance-none"
                    />
                    <button
                      className="text-md font-bold text-white px-4 py-1 bg-canvared hover:scale-125 rounded-full transition"
                      onClick={() => handleClaimMenuItem(item.id, claimQuantity)}
                    >
                      CLAIM
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
          <p className="text-white text-md">Quantity: {item.claimedBy.length} of {item.quantity} claimed</p>
          <p className="text-white text-md mb-8">
            Claimed By: {
              Object.entries(
                item.claimedBy.reduce((acc, email) => {
                  acc[email] = (acc[email] || 0) + 1; // Count claims per user
                  return acc;
                }, {})
              )
              .map(([email, count]) => {
                const displayName = userMap[email] || email;
                return count > 1 ? `${displayName} (${count}x)` : displayName;
              })
              .join(', ') || 'None'
            }
          </p>
          <div className="absolute bottom-4 left-4 text-md font-bold italic text-yellow">{item.category}</div>
          <div className="absolute bottom-4 right-4 flex gap-2">
            {item.addedBy === JSON.parse(localStorage.getItem('user')).email && (
              <>
                <button className="text-white hover:scale-125 text-fontGreen transition" onClick={() => handleEditMenuItem(item)}>
                  <FiEdit size={20} />
                </button>
                <button className="text-white hover:scale-125 text-fontGreen transition" onClick={() => handleDeleteMenuItem(item.id)}>
                  <FiTrash size={20} />
                </button>
              </>
            )}
          </div>
        </motion.div>
      ))}
    </div>
  </div>
)}




  
          {/* Modal Section */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h3 className="text-2xl font-semibold text-primary mb-4">{isEditMode ? 'Edit Menu Item' : 'Add New Menu Item'}</h3>
                <form onSubmit={handleAddMenuItem} className="flex flex-col gap-4">
                  <input
                    type="text"
                    name="name"
                    placeholder="Item Name"
                    value={newMenuItem.name}
                    onChange={(e) => setNewMenuItem({ ...newMenuItem, name: e.target.value })}
                    className="p-3 border border-gray-300 rounded"
                    required
                  />
                  <select
                    name="category"
                    value={newMenuItem.category}
                    onChange={(e) => setNewMenuItem({ ...newMenuItem, category: e.target.value })}
                    className="p-3 border border-gray-300 rounded"
                  >
                    <option value="Mains">Mains</option>
                    <option value="Appetizers">Appetizers</option>
                    <option value="Sides">Sides</option>
                    <option value="Desserts">Desserts</option>
                    <option value="Drinks">Drinks</option>
                    <option value="Others">Others</option>
                  </select>
                  <input
                    type="number"
                    name="quantity"
                    placeholder="Quantity"
                    value={newMenuItem.quantity}
                    onChange={(e) => setNewMenuItem({ ...newMenuItem, quantity: Math.max(1, parseInt(e.target.value) || 1) })}
                    className="p-3 border border-gray-300 rounded"
                    min="1"
                  />
                  <input
                    type="text"
                    name="allergyInfo"
                    placeholder="Allergy Info (Optional)"
                    value={newMenuItem.allergyInfo}
                    onChange={(e) => setNewMenuItem({ ...newMenuItem, allergyInfo: e.target.value })}
                    className="p-3 border border-gray-300 rounded"
                  />
                  <div className="flex justify-between mt-4">
                   
                  <button type="button" className="bg-gradient-to-r from-medgrey to-medgrey text-white py-2 px-4 rounded-2xl hover: transition" onClick={closeModal}>
                      Cancel
                    </button>
                    <button type="submit" className="bg-gradient-to-r from-fontGreen to-fontGreen text-white py-2 px-4 rounded-2xl hover: transition">
                      {isEditMode ? 'Update Item' : 'Add Item'}
                    </button>
                   

                  
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
      </main>
    </div>
  );
  
}

export default GuestEventDetails;
